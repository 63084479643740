/* eslint-disable no-unused-vars */
import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import get from 'lodash/get';
import { handleApiError } from '../store/app';
import { IActionTypes } from './defActionTypes';
import { ICustomHistory } from '../hooks/useCustomHistory';
import { captureAndReportError } from './captureAndReportError';
import { ErrorResponse, IResponse } from '../types/otherData';
import { isKnownErrorResponse } from '../types/typeGuards';

const noop = () => {};

interface IThunkStep<TData> {
  prevResponse: any;
  data?: TData;
  history?: ICustomHistory;
  getState: () => RootState;
  dispatch: Dispatch;
}

type SilentErrorType = boolean | ((resp: ErrorResponse) => boolean);

export type ThunkStep<TData> = ({
  prevResponse,
  data,
  history,
  dispatch,
}: IThunkStep<TData> & {data: TData}) => any;

interface IDefThunkRequest<TData> {
  actionTypes: IActionTypes;
  redirectUrl?: string;
  silentError?: SilentErrorType;
  thunkSteps: Array<ThunkStep<TData>>;
}

interface IThunkRequest<TData, TRes> {
  data?: TData,
  history?: ICustomHistory,
  onSuccess?: (res: IResponse<TRes>) => void;
  onFailure?: Function;
}

const isSilentError = (resp: unknown, silentError?: SilentErrorType) => {
  if (isKnownErrorResponse(resp) && typeof silentError === 'function') {
    return silentError(resp);
  }

  return silentError;
};

const captureSentryError = (error: any) => {
  if (!error.raw) {
    captureAndReportError({ error });
    return;
  }

  const {
    status,
    statusText,
    url,
    type,
    redirected,
  } = error.raw;
  const errorStatus = get(error, 'data.status');
  const isTokenExpired = status === 401 && errorStatus === 'token_expired';
  const isKnownUrl = status === 401 && url?.includes('auth/invite');
  const inviteTokenNotExist = status === 404 && url?.includes('auth/invite');
  const accountLookupDontExist = status === 404 && url?.includes('lender_account/lookup');

  if (isTokenExpired || isKnownUrl || inviteTokenNotExist || accountLookupDontExist) return;

  const customError = new Error(`An error "${status}: ${statusText}" occurred while fetching "${url}"`);
  captureAndReportError({
    error: customError,
    disableRedirect: true,
    extra: {
      response: {
        status,
        body: error.data,
        statusText,
        url,
        type,
        redirected,
      },
    },
  });
};

export function defThunkRequest<TData = any, TRes = unknown>({
  actionTypes,
  thunkSteps,
  redirectUrl,
  silentError,
}: IDefThunkRequest<TData>) {
  return ({
    data = {} as TData,
    history,
    onSuccess = noop,
    onFailure = noop,
  }: IThunkRequest<TData, TRes>): ThunkAction<void, any, unknown, AnyAction> => {
    if (redirectUrl && history) {
      history.push(redirectUrl);
    }
    return async (dispatch: Dispatch, getState: () => RootState) => {
      dispatch(actionTypes.request(data));
      try {
        let prevResponse = null;
        for (let i = 0; i < thunkSteps.length; i++) {
          const currentStepAction = thunkSteps[i];
          // eslint-disable-next-line no-await-in-loop
          prevResponse = await currentStepAction({
            prevResponse,
            data,
            history,
            dispatch,
            getState,
          });
        }
        dispatch(actionTypes.success(prevResponse, history));
        onSuccess(prevResponse);
      } catch (error) {
        if (!isSilentError(error, silentError)) captureSentryError(error);

        if (history) {
          handleApiError({
            error,
            dispatch,
            requestName: actionTypes.REQUEST,
            history,
            additionalAction: actionTypes.failure,
          });
        } else {
          dispatch(actionTypes.failure(error));
        }
        onFailure(error);
      }
    };
  };
}
