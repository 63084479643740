import { ROOT } from 'routes';
import { defRoute } from 'utils/defRoute';
import { joinUrlParts } from 'utils/urlTools';

export const APPLICATION_PAGE = defRoute(joinUrlParts(ROOT.pattern, 'application'));
export const SUBMISSION_FLOW = defRoute(joinUrlParts(APPLICATION_PAGE.pattern, '/:submissionId'));

// Creation
export const CREATION_FORM = defRoute(joinUrlParts(APPLICATION_PAGE.pattern, 'form'));

// Created
export const CREATED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'created'));
export const MISSING_INFO = defRoute(joinUrlParts(CREATED_PAGE.pattern, 'missingInfo'));
export const PRE_EXISTING_APP = defRoute(joinUrlParts(CREATED_PAGE.pattern, 'preExistingApp'));

// PreApproved
export const PRE_APPROVED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'preApproved'));
export const OFFERS_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'offers'));
export const PARTIAL_APPROVAL_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'partial-approval'));
export const PLAN_OVERVIEW_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'planOverview'));
export const APPROVING_LOADING_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'approving'));

export const IDEAL_OFFERS_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'idealOffers'));

export const GENESIS_PRE_APPROVED_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'genesis'));

export const AFF_PAYMENT_CARD_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'payment'));
export const AFF_EMPLOYMENT_DETAILS_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'employment'));

export const POWER_PAY_INFO_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'collectDrivingLicense'));
export const PREQUALIFIED_PAGE = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'prequalified'));
export const CARE_CREDIT_EXTEND_SESSION = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'session'));
export const CARE_CREDIT_EXTEND_SESSION_FAILED = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'session_failed'));
export const CARE_CREDIT_VERIFY_INFO = defRoute(joinUrlParts(PRE_APPROVED_PAGE.pattern, 'verify_info'));

// Approved
export const APPROVED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'approved'));
export const REDUCED_LIMIT_PAGE = defRoute(joinUrlParts(APPROVED_PAGE.pattern, 'reducedLimit'));
export const APPLICATION_APPROVED_PAGE = defRoute(joinUrlParts(APPROVED_PAGE.pattern, 'approved'));
export const FORTIVA_APPROVED_TERMS_PAGE = defRoute(joinUrlParts(APPROVED_PAGE.pattern, 'fortivaTerms'));

// Charge
export const CHARGE_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'charge'));
export const CHARGE_APPROVED_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'approved'));
export const REVIEW_APPROVED_OFFER = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'reviewOffer'));
export const IDEAL_PLAN_CHANGED_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'planChanged'));
export const APPLICATION_REVIVED_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'accountRevived'));
export const ALPHAEON_COLLECT_DOCUMENTS_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'document'));
export const DEFAULT_SIGN_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'defaultSign'));
export const CHARGE_SUCCESS_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'Success'));
export const TRANSACTION_SIGNED_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'signed'));
export const TRANSACTION_FAILED_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'transactionFailed'));

export const POWER_PAY_SIGN_AGREEMENT_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'powerPaySign'));

export const HFD_PAYMENT_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'hfdPayment'));
export const HFD_CHANGE_PAYMENT_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'hfdChangePayment'));
export const HFD_RETRY_TRANSACTION_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'hfdRetryTransaction'));
export const HFD_SIGN_AGREEMENT_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'hfdSignAgreement'));
export const HFD_SIGN_FAILED = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'hfdSignFailed'));

export const FORTIVA_SIGN_AGREEMENT_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'fortivaSign'));

export const LINK_EXPIRED_PAGE = defRoute(joinUrlParts(CHARGE_PAGE.pattern, 'linkExpired'));

// Shared
export const PENDING_APPLICATION = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/pending'));
export const FROZEN_CREDIT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/frozen'));
export const APPLICATION_INCOMPLETE_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/incomplete'));
export const KBA_CHALLENGE_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/kbaChallenge'));
export const GREATAMERICA_DETAILS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/greatAmericaDetails'));
export const ACCOUNT_SUSPENDED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/accountSuspended'));
export const TEMPORARY_ERROR_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/temporaryError'));
export const HARD_PULL_PENDING_APPLICATION = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/hardPullPending'));
export const EXPIRED_OFFER = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, '/:stage/expired'));

// Other
export const TIMEOUT_APPLICATION = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'timeout'));
export const DECLINED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'declined'));
export const BLANK_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'loading'));
export const SUBMISSION_LOADING_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'applyingSubmission'));
export const HARDPULL_DECLINE_REVIEW = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hardpullDeclineReview'));
export const PREQUAL_DECLINE_REVIEW = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'prequalDeclineReview'));
