import React from 'react';
import { IconProps } from './types';

type Props = {
    children?: React.ReactNode;
    sizeX: number | string;
    sizeY: number | string;
} & IconProps;

export const BaseIcon = ({
  children, position, sizeX, sizeY, color = 'none',
}: Props) => {
  return (
    <svg
      width={sizeX}
      height={sizeY}
      viewBox={`0 0 ${sizeX} ${sizeY}`}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={position}
    >
      {children}
    </svg>
  );
};
