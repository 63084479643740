import { combineReducers } from 'redux';
import { appReducer } from './app';
import { applicationReducer } from './application';
import { authReducer } from './auth';
import {
  adminApplicationsReducer,
} from './adminApplications';
import { submissionReducer } from './submission';
import { usersReducer } from './users';
import { splititReducer, splititApplicationsReducer } from './spliIt';
import { locationDictionaryReducer, locationReducer, locationsReducer } from './locations';
import { configurationsReducer } from './configurations';
import { offersReducer } from './offers';
import { regionsReducer } from './regions';
import { lenderApplicationsReducer } from './lenderApplications';
import { inviteReducer } from './invite';
import { lenderSpecificReducer } from './lenderSpecific';
import { accountLookupReducer } from './accountLookup';
import { transactionReducer, transactionsReducer } from './transactions';
import { lenderAccountReducer } from './lenderAccount';
import { networksReducer } from './networks';
import { transactionReportsReducer } from './transactionReports';
import { applicationLogsReducer } from './applicationLogs';
import { applicantReducer } from './applicant';
import { supportMerchantReportsReducer } from './supportMerchantReports';
import { lendersStatusReducer } from './lendersStatus';
import { lenderAccountsReducer } from './lenderAccounts';
import { kbaReducer } from './kbaChallenge';
import { ssoDomainReducer } from './ssoDomain';
import { customerReducer } from './customers';
import { customerAccountsReducer } from './customerAccounts';
import { quotesReducer } from './quotes';
import { customerInviteLocationReducer } from './customerInviteLocation';

export default combineReducers({
  app: appReducer,
  application: applicationReducer,
  auth: authReducer,
  applicant: applicantReducer,
  networks: networksReducer,
  applicationLogs: applicationLogsReducer,
  transaction: transactionReducer,
  transactions: transactionsReducer,
  customer: customerReducer,
  quotes: quotesReducer,
  transactionReports: transactionReportsReducer,
  lenderAccount: lenderAccountReducer,
  lenderAccounts: lenderAccountsReducer,
  adminApplications: adminApplicationsReducer,
  lendersStatus: lendersStatusReducer,
  submission: submissionReducer,
  users: usersReducer,
  splitit: splititReducer,
  supportMerchantReports: supportMerchantReportsReducer,
  splititApplications: splititApplicationsReducer,
  locationDictionary: locationDictionaryReducer,
  locations: locationsReducer,
  location: locationReducer,
  configurations: configurationsReducer,
  offers: offersReducer,
  ssoDomains: ssoDomainReducer,
  regions: regionsReducer,
  lenderApplications: lenderApplicationsReducer,
  invite: inviteReducer,
  lenderSpecific: lenderSpecificReducer,
  accountLookup: accountLookupReducer,
  kba: kbaReducer,
  customerAccounts: customerAccountsReducer,
  customerInviteLocation: customerInviteLocationReducer,
});
