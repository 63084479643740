import { apiRequest } from 'utils/apiRequest';

export const getCustomerApiRequest = (id: string) => apiRequest(
  `customer/${id}`,
  {
    method: 'GET',
  },
);

export const getCustomerAccountsApiRequest = (id: string) => apiRequest(
  `customer/${id}/accounts`,
  {
    method: 'GET',
  },
);
