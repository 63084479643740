import { AnyAction } from 'redux';

import { RESET_APP } from 'store/app';
import { v4 as uuid } from 'uuid';
import {
  createTransactionAction,
  getTransactionAction,
  getTransactionsAction,
  replaceTransactionAction,
  RESET_TRANSACTIONS,
  retryTransactionAction, UPDATE_TRANSACTION,
} from './actionTypes';
import { ITransactionReducer, ITransactionsReducer } from './types';
import { ITransaction } from '../../types/transaction';
import { includesInUnion } from '../../utils/includesInUnion';
import { TRANSACTION_STAGE, TRANSACTION_STATUSES } from '../../applicationConstants';
import { setPaymentDateForHfdAction } from '../application';

const initialTransactionsState: ITransactionsReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

type UpdateType = {
  wsUpdateType?: 'transactionChanged';
  updateUUIID?: string;
  updateCanChargeUUID?: string;
  updatedTransaction?: ITransaction;
};

const getWSUpdateType = (transaction1: ITransaction, transaction2: ITransaction): UpdateType => {
  if (transaction1.stage === transaction2.stage
    && transaction1.status === transaction2.status) return { };

  const isFinished = (includesInUnion([
    TRANSACTION_STAGE.settled,
    TRANSACTION_STAGE.signed,
    TRANSACTION_STAGE.refunded,
    TRANSACTION_STAGE.voided,
    TRANSACTION_STAGE.failed,
    TRANSACTION_STAGE.declined,
  ], transaction2.stage)
    && includesInUnion([
      TRANSACTION_STATUSES.ok,
    ], transaction2.status))
  || includesInUnion([
    TRANSACTION_STATUSES.error,
    TRANSACTION_STATUSES.config_error,
    TRANSACTION_STATUSES.failed,
  ], transaction2.status);
  if (!isFinished) {
    return {
      updateCanChargeUUID: uuid(),
    };
  }

  return {
    wsUpdateType: 'transactionChanged',
    updateUUIID: uuid(),
    updatedTransaction: transaction2,
  };
};

export function transactionsReducer(
  state = initialTransactionsState,
  action: AnyAction,
): ITransactionsReducer & UpdateType {
  const { payload, type } = action;

  switch (type) {
    case getTransactionsAction.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getTransactionsAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case UPDATE_TRANSACTION: {
      if (state.status !== 'loaded') return state;
      const existedTransaction = state.data?.items.find((x) => x.id === payload.id);
      if (existedTransaction) {
        return {
          ...state,
          data: {
            ...state.data,
            items: state.data?.items.map((x) => {
              if (x.id === payload.id) return payload;
              return x;
            }) || [],
            total: state.data?.total || 0,
            offset: state.data?.offset || 0,
          },
          ...getWSUpdateType(
            existedTransaction,
            payload,
          ),
        };
      }
      return {
        ...state,
        updateCanChargeUUID: uuid(),
        data: {
          ...state.data,
          items: [payload, ...(state.data?.items || [])],
          total: state.data?.total ? state.data.total + 1 : 0,
          offset: state.data?.offset || 0,
        },
      };
    }
    case getTransactionsAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case RESET_APP:
    case RESET_TRANSACTIONS:
      return { ...initialTransactionsState };
    default:
      return state;
  }
}

const initialApplicationState: ITransactionReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

export function transactionReducer(
  state = initialApplicationState,
  action: AnyAction,
): ITransactionReducer {
  const { payload, type } = action;
  switch (type) {
    case UPDATE_TRANSACTION: {
      if (state.status !== 'loaded' || state.data?.id !== payload.id) return state;
      return {
        ...state,
        data: payload,
      };
    }
    case getTransactionAction.REQUEST:
    case createTransactionAction.REQUEST:
    case retryTransactionAction.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getTransactionAction.SUCCESS:
    case createTransactionAction.SUCCESS:
    case retryTransactionAction.SUCCESS:
    case setPaymentDateForHfdAction.SUCCESS:
    case replaceTransactionAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getTransactionAction.FAILURE:
    case createTransactionAction.FAILURE:
    case retryTransactionAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case RESET_APP:
      return initialApplicationState;
    default:
      return state;
  }
}
