import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { getInviteMode } from '../../utils/apiInviteKey';
import { downloadFile } from '../../utils/downloadFileByFetch';

export const getTransactionsRequest = (submissionId: string) => apiRequest(
  `submission/${submissionId}/transactions`, {
    method: 'get',
  },
);

export const getTransactionRequest = (transactionId: string) => apiRequest(
  `transaction/${transactionId}${toParams({ invite: getInviteMode() })}`, {
    method: 'get',
  },
);

export const confirmRefundTransactionRequest = (transactionId: string) => apiRequest(
  `transaction/${transactionId}`, {
    method: 'PATCH',
    body: {
      stage: 'settled',
      status: 'ok',
      comments: 'manual transition by support user',
    },
  },
);

export const createTransactionRequest = ({
  offer,
  amount,
  reference,
  extra,
  lenderApplicationId,
}: {
    amount?: number;
    offer?: string;
    reference?: string;
    lenderApplicationId?: string;
    extra?: any
}) => apiRequest(
  `transaction${toParams({ invite: getInviteMode() })}`, {
    method: 'post',
    body: {
      offer,
      amount,
      reference,
      extra,
      lender_application: lenderApplicationId,
    },
  },
);

export const submitTransactionRequest = ({
  transactionId,
  data = {},
}: {transactionId: string; data?: any}) => apiRequest(
  `transaction/${transactionId}/charge${toParams({ invite: getInviteMode() })}`, {
    method: 'post',
    body: {
      extra: data,
    },
  },
);

export const retryTransactionRequest = ({ transactionId }: { transactionId: string }) => apiRequest(
  `transaction/${transactionId}/retry${toParams({ invite: getInviteMode() })}`, {
    method: 'POST',
  },
);
export const fundSandboxTransactionRequest = ({ transactionId }: { transactionId: string }) => apiRequest(
  `ally/fund_sandbox_transaction/${transactionId}`, {
    method: 'post',
  },
);

export const setTransactionOwnerRequest = ({ transactionId }: { transactionId: string }) => apiRequest(
  `transaction/${transactionId}/set_owner`, {
    method: 'post',
  },
);

export const voidTransactionRequest = ({ transactionId }: { transactionId: string }) => apiRequest(
  `transaction/${transactionId}/void`, {
    method: 'post',
  },
);

export const cancelTransactionRequest = ({ transactionId }: { transactionId: string }) => apiRequest(
  `transaction/${transactionId}`, {
    method: 'DELETE',
  },
);
export const replaceTransactionRequest = ({
  transactionId,
  offerId,
  lenderApplicationId,
  amount,
  extra,
  submit,
}: {
  transactionId: string;
  offerId?: string;
    lenderApplicationId?: string;
  amount?: number;
  extra?: any,
  submit?: boolean,
}) => apiRequest(
  `transaction/${transactionId}/replace`, {
    method: 'POST',
    body: {
      extra,
      amount,
      lender_application: lenderApplicationId,
      offer: offerId,
      submit: !!submit,
    },
  },
);

export const confirmSigningTransactionRequest = ({ transactionId }: { transactionId: string }) => apiRequest(
  `transaction/${transactionId}/confirm-signing`, {
    method: 'post',
  },
);

export const refundTransactionRequest = ({
  transactionId,
  body,
  amount,
}: { transactionId: string, amount: number, body: { reason?: string, }}) => apiRequest(
  `transaction/${transactionId}/refund${toParams({ amount })}`, {
    method: 'post',
    body,
  },
);

export type ReportsParams = {
    location?: string;
    network?: string;
    lenders?: Array<string>;
    settledFrom?: string;
    settledTo?: string;
    paidFrom?: string;
    paidTo?: string;
    fileFormat?: 'csv' | 'json';
    start?: number | string;
    count?: number | string;
};

const transformReportsParamsToQueryParams = (body: ReportsParams) => {
  return toParams({
    location: body.location,
    network: body.network,
    lenders: body.lenders,
    settled_from: body.settledFrom,
    settled_to: body.settledTo,
    paid_from: body.paidFrom,
    paid_to: body.paidTo,
    file_format: body.fileFormat || 'json',
    start: body.start,
    count: body.count,
  });
};

export const getTransactionReportsRequest = (body: ReportsParams) => apiRequest(
  `transaction_log${
    transformReportsParamsToQueryParams(body)
  }`, {
    method: 'get',
  },
);

export const getTransactionReportsDownload = (body: ReportsParams) => downloadFile(
  `transaction_log${
    transformReportsParamsToQueryParams(body)
  }`, {
    method: 'get',
  },
);
export const getHfdReportsDownload = (body: ReportsParams) => downloadFile(
  `hfd_payment_log${
    transformReportsParamsToQueryParams(body)
  }`, {
    method: 'get',
  },
);

export const getManagementTransactionReportsDownload = (body: ReportsParams) => downloadFile(
  `invoice_transaction_log${
    transformReportsParamsToQueryParams(body)
  }`, {
    method: 'get',
  },
);

export type TransactionReportParams = {
  transactionId: string;
  utcOffset: number;
  aePhotoIdType?: string;
  aePhotoIdNumber?: string;
  aePhotoIdState?: string;
};

export const generateTransactionPdf = ({
  transactionId,
  ...body
}: TransactionReportParams) => apiRequest(
  `transaction/${transactionId}/pdf`, {
    method: 'POST',
    body,
  },
);

export const generateAEPreSignedTransactionPdf = ({
  transactionId,
  ...body
}: TransactionReportParams) => apiRequest(
  `alphaeon2/${transactionId}/presign_pdf`, {
    method: 'POST',
    body,
  },
);
