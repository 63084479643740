import React from 'react';
import { Box } from '@material-ui/core';
import CredCompareSVGIcon, { IconNameType } from '../CredCompareSVGIcons';
import { DangerousComponentInjector } from '../DangerousComponentInjector';
import { CcTypography } from '../CcTypography';

type Props = {
    title?: string;
    titleIcon: IconNameType;
    titleParams?: any;
};

export const AlertTitle = ({
  titleParams,
  title,
  titleIcon,
}: Props) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      marginBottom="var(--spacing-m)"
    >
      {
                titleIcon && (
                <Box marginBottom="16px">
                  <CredCompareSVGIcon
                    width={96}
                    height={96}
                    name={titleIcon}
                  />
                </Box>
                )
            }
      <Box width="100%" textAlign="center">
        <DangerousComponentInjector
          component={CcTypography}
          text={title}
          fontWeight={600}
          variant="h2"
          params={titleParams}
        />
      </Box>
    </Box>
  );
};
