import React, { useMemo } from 'react';
import cx from 'classnames';
import boxStyles from './styles.module.scss';

export type AllowedSpacings = 'xs' | 's' | 'm' | 'l' | 'none';

type SpacingValues = {
  top?: AllowedSpacings;
  right?: AllowedSpacings;
  bottom?: AllowedSpacings;
  left?: AllowedSpacings;
};

type Spacing = AllowedSpacings
  | `${AllowedSpacings} ${AllowedSpacings}`
  | `${AllowedSpacings} ${AllowedSpacings} ${AllowedSpacings}`
  | `${AllowedSpacings} ${AllowedSpacings} ${AllowedSpacings} ${AllowedSpacings}`;

export type SpacingType = Spacing | SpacingValues;

type Props<C extends React.ElementType<any>> = {
  children?: React.ReactNode;
  className?: string;
  padding?: SpacingType;
  margin?: SpacingType;
  component?: C;
  display?: 'flex' | 'block';
} & React.ComponentProps<C>;

const paddings = {
  top: {
    xs: boxStyles.paddingXsT,
    s: boxStyles.paddingST,
    m: boxStyles.paddingMT,
    l: boxStyles.paddingLT,
    none: '0px',
  },
  bottom: {
    xs: boxStyles.paddingXsB,
    s: boxStyles.paddingSB,
    m: boxStyles.paddingMB,
    l: boxStyles.paddingLB,
    none: '0px',
  },
  left: {
    xs: boxStyles.paddingXsL,
    s: boxStyles.paddingSL,
    m: boxStyles.paddingML,
    l: boxStyles.paddingLL,
    none: '0px',
  },
  right: {
    xs: boxStyles.paddingXsR,
    s: boxStyles.paddingSR,
    m: boxStyles.paddingMR,
    l: boxStyles.paddingLR,
    none: '0px',
  },
} as const;

const margins = {
  top: {
    xs: boxStyles.marginXsT,
    s: boxStyles.marginST,
    m: boxStyles.marginMT,
    l: boxStyles.marginLT,
    none: '0px',
  },
  bottom: {
    xs: boxStyles.marginXsB,
    s: boxStyles.marginSB,
    m: boxStyles.marginMB,
    l: boxStyles.marginLB,
    none: '0px',
  },
  left: {
    xs: boxStyles.marginXsL,
    s: boxStyles.marginSL,
    m: boxStyles.marginML,
    l: boxStyles.marginLL,
    none: '0px',
  },
  right: {
    xs: boxStyles.marginXsR,
    s: boxStyles.marginSR,
    m: boxStyles.marginMR,
    l: boxStyles.marginLR,
    none: '0px',
  },
} as const;

export const CcBox = <C extends React.ElementType<any>>({
  children,
  className,
  padding,
  margin,
  component: Component = 'div',
  display,
  ...props
}: Props<C>) => {
  const spacingClass = useMemo(() => {
    const res: Array<string> = [];

    if (typeof padding === 'string') {
      const splited = padding.split(' ') as Array<AllowedSpacings>;

      if (splited.length === 1) {
        const [first] = splited;
        res.push(...[
          paddings.top[first],
          paddings.bottom[first],
          paddings.left[first],
          paddings.right[first],
        ]);
      }

      if (splited.length === 2) {
        const [first, second] = splited;
        res.push(...[
          paddings.top[first],
          paddings.bottom[first],
          paddings.left[second],
          paddings.right[second],
        ]);
      }

      if (splited.length === 3) {
        const [first, second, third] = splited;
        res.push(...[
          paddings.top[first],
          paddings.bottom[third],
          paddings.left[second],
          paddings.right[second],
        ]);
      }

      if (splited.length === 4) {
        const [first, second, third, forth] = splited;
        res.push(...[
          paddings.top[first],
          paddings.bottom[third],
          paddings.left[forth],
          paddings.right[second],
        ]);
      }
    }
    if (typeof margin === 'string') {
      const splited = margin.split(' ') as Array<AllowedSpacings>;

      if (splited.length === 1) {
        const [first] = splited;
        res.push(...[
          margins.top[first],
          margins.bottom[first],
          margins.left[first],
          margins.right[first],
        ]);
      }

      if (splited.length === 2) {
        const [first, second] = splited;
        res.push(...[
          margins.top[first],
          margins.bottom[first],
          margins.left[second],
          margins.right[second],
        ]);
      }

      if (splited.length === 3) {
        const [first, second, third] = splited;
        res.push(...[
          margins.top[first],
          margins.bottom[third],
          margins.left[second],
          margins.right[second],
        ]);
      }

      if (splited.length === 4) {
        const [first, second, third, forth] = splited;
        res.push(...[
          margins.top[first],
          margins.bottom[third],
          margins.left[forth],
          margins.right[second],
        ]);
      }
    }
    if (typeof padding === 'object') {
      const pp = padding as SpacingValues;
      if (pp.top && pp.top !== 'none') {
        res.push(paddings.top[pp.top]);
      }
      if (pp.right && pp.right !== 'none') {
        res.push(paddings.right[pp.right]);
      }
      if (pp.left && pp.left !== 'none') {
        res.push(paddings.left[pp.left]);
      }
      if (pp.bottom && pp.bottom !== 'none') {
        res.push(paddings.bottom[pp.bottom]);
      }
    }
    if (typeof margin === 'object') {
      const marginValues = margin as SpacingValues;

      if (marginValues.top && marginValues.top !== 'none') {
        res.push(margins.top[marginValues.top]);
      }
      if (marginValues.right && marginValues.right !== 'none') {
        res.push(margins.right[marginValues.right]);
      }
      if (marginValues.left && marginValues.left !== 'none') {
        res.push(margins.left[marginValues.left]);
      }
      if (marginValues.bottom && marginValues.bottom !== 'none') {
        res.push(margins.bottom[marginValues.bottom]);
      }
    }
    return res.join(' ');
  }, [padding, margin]);

  return (
    <Component
      className={cx(
        spacingClass,
        className,
        display === 'flex' && boxStyles.flex,
        display === 'block' && boxStyles.block,
      )}
      {...props}
    >
      {
        children
      }
    </Component>
  );
};
