import { joinUrlParts } from './utils/urlTools';
import { defRoute } from './utils/defRoute';

export const ROOT = defRoute('/');
export const NOT_FOUND = defRoute('/404');
export const ADMIN = defRoute('/admin');
export const MY_ACCOUNT = defRoute('/myAccount');
export const CUSTOMER = defRoute('/customer/:customerId');

export const SUPPORT_NETWORKS = defRoute('/supportNetworks');
export const SUPPORT_PORTAL = defRoute(joinUrlParts(SUPPORT_NETWORKS.pattern, 'portal'));
// eslint-disable-next-line max-len
export const SUPPORT_CHANGE_NETWORK_PORTAL = defRoute(joinUrlParts(SUPPORT_NETWORKS.pattern, 'follow/:network/:submission'));
export const SUPPORT_PORTAL_APPLICANT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'applicant'));
export const SUPPORT_PORTAL_APPLICATION = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'application'));
export const SUPPORT_PORTAL_MERCHANT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'merchant'));
export const SUPPORT_PORTAL_MANAGEMENT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'management'));
export const SUPPORT_LENDERS_MANAGEMENT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'lenders'));
export const SUPPORT_PORTAL_TRANSACTIONS = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'transactions'));
export const SUPPORT_PORTAL_USERS = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'users'));

export const MANAGE_USERS = defRoute(joinUrlParts(ADMIN.pattern, 'manageUsers'));
export const MANAGE_LENDERS = defRoute(joinUrlParts(ADMIN.pattern, 'manageLenders'));
export const MANAGE_LOCATION_LENDERS = defRoute(joinUrlParts(MANAGE_LENDERS.pattern, '/:locationId'));
export const BANK_RECONCILIATION = defRoute(joinUrlParts(ADMIN.pattern, 'bankReconciliation'));
export const FINANCING_REPORTS = defRoute(joinUrlParts(BANK_RECONCILIATION.pattern, 'financial'));
export const SERVICING_REPORTS = defRoute(joinUrlParts(BANK_RECONCILIATION.pattern, 'servicing'));

export const MANAGE_APPLICATIONS = defRoute(joinUrlParts(ADMIN.pattern, 'manageApplications'));
export const MANAGE_LENDER_APPLICATIONS = defRoute(joinUrlParts(MANAGE_APPLICATIONS.pattern, 'lender'));
export const MANAGE_LENDER_APPLICATION = defRoute(joinUrlParts(MANAGE_APPLICATIONS.pattern, 'lender/:submission'));
export const MANAGE_INSTALLMENT_APPLICATIONS = defRoute(joinUrlParts(MANAGE_APPLICATIONS.pattern, 'installment'));

export const MANAGE_NETWORK = defRoute(joinUrlParts(ADMIN.pattern, 'manageNetwork'));
export const MANAGE_NETWORK_LOCATIONS = defRoute(joinUrlParts(MANAGE_NETWORK.pattern, 'locations'));
export const MANAGE_NETWORK_REGIONS = defRoute(joinUrlParts(MANAGE_NETWORK.pattern, 'regions'));

export const ERROR_PAGE = defRoute(joinUrlParts(ROOT.pattern, 'error'));

export const APPLICATION_PAGE = defRoute(joinUrlParts(ROOT.pattern, 'application'));
export const SUBMISSION_FLOW = defRoute(joinUrlParts(APPLICATION_PAGE.pattern, '/:submissionId'));

export const PENDING_APPLICATION = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'pending'));
export const HARD_PULL_PENDING_APPLICATION = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hardPullPending'));
export const TIMEOUT_APPLICATION = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'timeout'));
export const MISSING_INFO = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'missingInfo'));
export const OFFERS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'offers'));
export const IDEAL_OFFERS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'idealOffers'));
export const REVIEW_APPROVED_OFFER = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'reviewApprovedOffer'));
export const IDEAL_PLAN_CHANGED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'idealPlanChanged'));
export const PARTIAL_APPROVAL_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'partial-approval'));
export const REDUCED_LIMIT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'reducedLimit'));
export const DECLINED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'declined'));
export const FROZEN_CREDIT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'frozen'));
export const PLAN_OVERVIEW_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'planOverview'));
export const APPLICATION_APPROVED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'applicationApproved'));
export const APPLICATION_INCOMPLETE_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'incomplete'));
export const APPLICATION_REVIVED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'accountRevived'));
export const TEMPORARY_ERROR_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'temporaryError'));
export const PREQUALIFIED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'prequalified'));
export const PREQUAL_DECLINE_REVIEW = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'prequalDeclineReview'));
export const HARDPULL_DECLINE_REVIEW = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hardpullDeclineReview'));

export const KBA_CHALLENGE_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'kbaChallenge'));

export const GENESIS_PRE_APPROVED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'genesisPreApproved'));

export const ALPHAEON_COLLECT_DOCUMENTS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'document'));
export const BLANK_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'loading'));

export const DEFAULT_SIGN_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'defaultSign'));
export const CHARGE_SUCCESS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'chargeSuccess'));
export const TRANSACTION_SIGNED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'transactionSigned'));

export const AFF_PAYMENT_CARD_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'paymentCard'));
export const AFF_EMPLOYMENT_DETAILS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'employmentDetails'));

export const GREATAMERICA_DETAILS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'greatAmericaDetails'));

export const POWER_PAY_INFO_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'powerPayInfo'));
export const POWER_PAY_SIGN_AGREEMENT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'powerSignAgreement'));
export const TRANSACTION_FAILED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'transactionFailed'));

export const HFD_PAYMENT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hfdPayment'));
export const HFD_CHANGE_PAYMENT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hfdChangePayment'));
export const HFD_RETRY_TRANSACTION_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hfdRetryTransaction'));
export const HFD_SIGN_AGREEMENT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hfdSignAgreement'));
export const FORTIVA_SIGN_AGREEMENT_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'fortivaSignAgreement'));
export const FORTIVA_APPROVED_TERMS_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'fortivaApprovedTerms'));
export const HFD_SIGN_FAILED = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'hfdSignFailed'));
export const SUBMISSION_LOADING_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'applyingSubmission'));
export const APPROVING_LOADING_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'approvingApplication'));
export const ACCOUNT_SUSPENDED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'accountSuspended'));
export const LINK_EXPIRED_PAGE = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'linkExpired'));
export const PRE_EXISTING_APP = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'preExistingApp'));
export const CARE_CREDIT_EXTEND_SESSION = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'session'));
export const CARE_CREDIT_EXTEND_SESSION_FAILED = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'session_failed'));
export const CARE_CREDIT_VERIFY_INFO = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'verify_info'));
export const EXPIRED_OFFER = defRoute(joinUrlParts(SUBMISSION_FLOW.pattern, 'expired'));

export const SPLIT_IT_HOW_IT_WORKS = defRoute(joinUrlParts(ROOT.pattern, 'how-it-works'));
export const SPLIT_IT = defRoute(joinUrlParts(ROOT.pattern, 'splitit'));
export const SPLIT_IT_APPLICATION = defRoute(joinUrlParts(ROOT.pattern, 'splitit/application'));
export const SPLIT_IT_CARD_SETUP = defRoute(joinUrlParts(ROOT.pattern, 'splitit/card-setup'));
export const SPLIT_IT_SUCCESS = defRoute(joinUrlParts(ROOT.pattern, 'splitit/success'));
export const SPLIT_IT_FAILURE = defRoute(joinUrlParts(ROOT.pattern, 'splitit/failure'));
export const SPLIT_IT_ERROR = defRoute(joinUrlParts(ROOT.pattern, 'splitit/error'));

export const TOKEN_ERROR = defRoute(joinUrlParts(ROOT.pattern, 'token_error'));
export const DISABLED_LENDER_ERROR = defRoute(joinUrlParts(ROOT.pattern, 'disabled_lender_error'));

export const GUEST_AUTHORIZATION = defRoute(joinUrlParts(ROOT.pattern, 'guest_auth'));

export const IMPORT_ACCOUNT = defRoute(joinUrlParts(ROOT.pattern, ''));
export const IMPORT_ACCOUNT_SUCCESS = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'success'));
export const IMPORT_ACCOUNT_TOO_MANY = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'too-many'));
export const IMPORT_ACCOUNT_FAILURE = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'failure'));
export const IMPORT_ACCOUNT_CONFIRM = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'confirm'));

export const CUSTOMER_EXISTING_CREDIT = defRoute(joinUrlParts(CUSTOMER.pattern, 'existingCredit'));
