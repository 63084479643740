import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  cancelTransactionRequest,
  confirmRefundTransactionRequest,
  confirmSigningTransactionRequest,
  createTransactionRequest,
  fundSandboxTransactionRequest,
  generateAEPreSignedTransactionPdf,
  generateTransactionPdf,
  getTransactionRequest,
  getTransactionsRequest,
  refundTransactionRequest,
  replaceTransactionRequest,
  retryTransactionRequest,
  setTransactionOwnerRequest,
  submitTransactionRequest,
  TransactionReportParams,
  voidTransactionRequest,
} from '../apiRequests/transactions';
import {
  createTransactionAction,
  refundLenderApplicationAction,
  voidTransactionAction,
  confirmSigningTransactionAction,
  retryTransactionAction,
  getTransactionAction,
  UPDATE_TRANSACTION,
  getTransactionsAction,
  confirmRefundTransactionAction,
  fundSandboxTransactionAction,
  setTransactionOwnerAction,
  generateTransactionPdfAction,
  generateAEPreSignedPdfAction,
  cancelTransactionAction,
  replaceTransactionAction,
} from './actionTypes';

import { TRANSACTION_STAGE, TRANSACTION_STATUSES } from '../../applicationConstants';
import { getLenderApplication } from '../application';
import { waitWhileCondition } from '../../utils/requestWithTimeout';
import { loadSubmission } from '../submission';
import { isTransactionFailed } from '../../utils/isTransactionFailed';
import { ITransaction } from '../../types/transaction';
import { IPaginationResponse, IResponse } from '../../types/otherData';
import { includesInUnion } from '../../utils/includesInUnion';

export const transactionWSUpdate = (transaction?:ITransaction) => ({
  type: UPDATE_TRANSACTION,
  payload: transaction,
});

const isPendingTransaction = (data: ITransaction) => {
  return data.status === TRANSACTION_STATUSES.pending
    || data.stage === TRANSACTION_STAGE.pending
    || data.inRequest;
};

export const waitForOkTransactionStatus = (response: any, timeout = 30) => {
  if (
    response
      && (!isPendingTransaction(response.data) || isTransactionFailed(response.data))
  ) {
    return response;
  }

  return waitWhileCondition({
    request: () => getTransactionRequest(response.data.id),
    condition: (data: any) => {
      return isPendingTransaction(data) && !isTransactionFailed(data);
    },
    timeout,
    every: 3,
  });
};

export const waitForCreatedPendingTransactionStatus = (response: any) => {
  if (
    response
      && (response.data.status === TRANSACTION_STATUSES.pending
          || response.data.stage === TRANSACTION_STATUSES.pending || isTransactionFailed(response.data))
  ) {
    return response;
  }

  return waitWhileCondition({
    request: () => getTransactionRequest(response.data.id),
    condition: (data: any) => {
      return data.status !== TRANSACTION_STATUSES.pending
          && data.stage !== TRANSACTION_STATUSES.pending
          && data.stage !== TRANSACTION_STAGE.settled
          && !isTransactionFailed(data);
    },
    timeout: 60,
    every: 3,
  });
};

export const getTransactionThunk = defThunkRequest<
  { transactionId: string, wait?: boolean; },
  ITransaction>({
    actionTypes: getTransactionAction,
    thunkSteps: [
      async ({ data: { transactionId, wait } }) => {
        const transactionResp = await getTransactionRequest(transactionId);
        if (wait) return waitForOkTransactionStatus(transactionResp, 45);
        return transactionResp;
      },
    ],
  });

export const confirmRefundTransactionThunk = defThunkRequest<{ transactionId: string, }>({
  actionTypes: confirmRefundTransactionAction,
  thunkSteps: [
    ({ data: { transactionId } }) => {
      return confirmRefundTransactionRequest(transactionId);
    },
  ],
});

export const getTransactionListThunk = defThunkRequest<{ submissionId: string }, IPaginationResponse<ITransaction>>({
  actionTypes: getTransactionsAction,
  thunkSteps: [
    ({ data: { submissionId } }) => getTransactionsRequest(submissionId),
  ],
});

export type CreateTransactionsProps = {
  createOnly?: boolean;
  transaction?: string;
  offerId?: string;
  lenderApplicationId?: string;
  amount?: number;
  extra?: any;
  waitForPending?: boolean;
  reloadApplicationForFailedTransaction?: boolean;
};

export const createTransaction = defThunkRequest<CreateTransactionsProps, ITransaction>({
  actionTypes: createTransactionAction,
  thunkSteps: [
    ({
      data: {
        offerId,
        transaction,
        amount,
        lenderApplicationId,
        extra,
      },
    }) => (transaction ? undefined : createTransactionRequest({
      offer: offerId,
      amount,
      lenderApplicationId,
      extra,
    })),
    ({
      prevResponse,
      data: {
        extra,
        createOnly,
        transaction,
      },
    }) => {
      if (createOnly) {
        return prevResponse;
      }
      const transactionId = prevResponse?.data?.id || transaction;
      return submitTransactionRequest({
        data: extra,
        transactionId,
      });
    },
    ({
      prevResponse, data: { waitForPending },
    }) => (waitForPending
      ? waitForCreatedPendingTransactionStatus(prevResponse)
      : waitForOkTransactionStatus(prevResponse)),
    async ({
      prevResponse, data: {
        reloadApplicationForFailedTransaction,
      }, dispatch, history,
    }) => {
      if (reloadApplicationForFailedTransaction && isTransactionFailed(prevResponse.data)) {
        const loadApplicationPromise = () => {
          return new Promise<void>((res, rej) => {
            dispatch(getLenderApplication({
              data: {
                id: prevResponse.data.lenderApplication,
                suppressWaiting: true,
              },
              history,
              onSuccess: () => res(),
              onFailure: () => rej(),
            }));
          });
        };
        await loadApplicationPromise();
        return prevResponse;
      }
      return prevResponse;
    },
  ],
});

export const confirmSigningTransactionThunk = defThunkRequest<{
  transactionId: string;
}, ITransaction>({
  actionTypes: confirmSigningTransactionAction,
  thunkSteps: [
    ({
      data: {
        transactionId,
      },
    }) => confirmSigningTransactionRequest({
      transactionId,
    }),
  ],
});

export const voidTransactionThunk = defThunkRequest<{
  transactionId: string;
}>({
  actionTypes: voidTransactionAction,
  thunkSteps: [
    ({
      data: {
        transactionId,
      },
    }) => voidTransactionRequest({
      transactionId,
    }),
    ({
      prevResponse,
    }) => waitForOkTransactionStatus(prevResponse),
  ],
});

export type RefundTransactionProps = {
  transactionId: string;
  reason?: string;
  amount: number;
};

export const reFundTransaction = defThunkRequest<RefundTransactionProps, IResponse<ITransaction>>({
  actionTypes: refundLenderApplicationAction,
  thunkSteps: [
    ({
      data: {
        transactionId,
        amount,
        reason,
      },
    }) => refundTransactionRequest({
      transactionId,
      amount,
      body: {
        reason,
      },
    }),
    ({
      prevResponse,
    }) => waitForOkTransactionStatus(prevResponse),
  ],
});

export const retryChargeLenderApplication = defThunkRequest<{
  transactionId: string;
  applicationId?: string;
  submissionId?: string;
}, ITransaction>({
  actionTypes: retryTransactionAction,
  thunkSteps: [
    ({
      data: { transactionId },
    }) => retryTransactionRequest({
      transactionId,
    }),
    ({
      prevResponse,
    }) => (
      waitForOkTransactionStatus(prevResponse)),
    ({
      prevResponse,
      data: { applicationId, submissionId },
      dispatch,
      history,
    }) => {
      if (!applicationId && submissionId) {
        dispatch(loadSubmission({
          data: {
            submissionId,
            suppressProceed: true,
            suppressWaiting: true,
          },
          history,
        }));
      }
      if (applicationId && (
        !includesInUnion([
          TRANSACTION_STATUSES.error,
          TRANSACTION_STATUSES.config_error,
        ], prevResponse.data.status)
          && prevResponse.data.stage !== TRANSACTION_STAGE.declined)) {
        dispatch(getLenderApplication({
          data: {
            id: applicationId,
            suppressWaiting: true,
          },
          history,
        }));
      }
      return prevResponse;
    },
  ],
});

export const fundSandboxTransaction = defThunkRequest<{
  transactionId: string;
}>({
  actionTypes: fundSandboxTransactionAction,
  thunkSteps: [
    ({
      data: { transactionId },
    }) => fundSandboxTransactionRequest({
      transactionId,
    }),
  ],
});

export const setTransactionOwner = defThunkRequest<{
  transactionId: string;
}>({
  actionTypes: setTransactionOwnerAction,
  thunkSteps: [
    ({
      data: { transactionId },
    }) => setTransactionOwnerRequest({
      transactionId,
    }),
  ],
});

export const generateTransactionPdfThunk = defThunkRequest<TransactionReportParams, {url: string}>({
  actionTypes: generateTransactionPdfAction,
  thunkSteps: [
    ({
      data,
    }) => generateTransactionPdf(data),
  ],
});

export const generateAEPreSignedPdfThunk = defThunkRequest<TransactionReportParams, {url: string}>({
  actionTypes: generateAEPreSignedPdfAction,
  thunkSteps: [
    ({
      data,
    }) => generateAEPreSignedTransactionPdf(data),
  ],
});

export const cancelTransactionThunk = defThunkRequest<{
  transactionId: string;
}>({
  actionTypes: cancelTransactionAction,
  thunkSteps: [
    ({ data: { transactionId } }) => cancelTransactionRequest({ transactionId }),
  ],
});

export const replaceTransactionThunk = defThunkRequest<{
  transactionId: string;
  offerId?: string;
  lenderApplicationId?: string;
  amount?: number;
  extra?: any;
  submit?: boolean;
}, ITransaction>({
  actionTypes: replaceTransactionAction,
  thunkSteps: [
    ({ data }) => replaceTransactionRequest(data),
  ],
});
